.splash-screen {
  height: 100vh;
  width: 100vw;
  background: url("/assets/beautiful-landscape-view-ocean.jpg") no-repeat center center fixed;
  background-size: cover;

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;

    .horizon-logo {
      width: 30rem;
      margin-bottom: 5rem;
    }
  }

  .auth-container {
    width: 100%;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  
    .access-token-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
      font-weight: 600;
    }

    .received {
      color: #616161;
      font-weight: 600;
    }

    .sign-out {
      padding-top: 4rem;
    }
  
    .horizon-logo {
      width: 30rem;
    }
  }
}


