.login-btn {
  text-align: center;
  border: 2px solid #616161;
  width: 12rem;
  border-radius: 5px;
  color: #616161;
  padding: 0.4rem;
  margin-top: 1rem;
  height: 1.6rem;
  cursor: pointer;

  .ms-icon {
    width: 1.6rem;
    float: left;
    position: relative;
  }

  .login-text {
    text-align: center;
    vertical-align: middle;
    font-size: 1.1 rem;
  }
}
