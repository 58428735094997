.background {
  height: 100vh;
  width: 100vw;
  background: url("/assets/beautiful-landscape-view-ocean.jpg") no-repeat center center fixed;
  background-size: cover;
}

.unauth-container {
  height: 22rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  
  .horizon-logo {
    width: 30rem;
  }
}
